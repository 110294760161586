const React = require('react');
const PropTypes = require('prop-types');
const Icon = require('./icon');
const HelpTooltip = require('../../ui/HelpTooltip');

class Header extends React.Component {
  onClickHeader = (event) => {
    // Avoid propagation if a link was clicked
    if (!event.target.href) {
      this.props.onClickHeader();
    }
  }

  headerClassName() {
    const { foldingLocator } = this.props;
    let className = 'b-step__header';

    if (foldingLocator) {
      className += ` ${foldingLocator}`;
    }

    return className;
  }

  renderTitle() {
    const { title, dangerousTitle } = this.props;

    if (dangerousTitle) {
      return (
        <h1 className="b-step__header__title" dangerouslySetInnerHTML={{ __html: title }} />
      );
    }

    return (
      <h1 className="b-step__header__title">{title}</h1>
    );
  }

  renderHeaderTip() {
    const { headerTip } = this.props;

    if (!headerTip) return null;

    return (
      <div className="b-step__header__tip">
        <HelpTooltip text={I18n.t(`negotiation_dashboard.tips.${headerTip}`)} />
      </div>
    );
  }

  render() {
    const {
      number,
      done,
      checkmark,
    } = this.props;

    return (
      <div className={this.headerClassName()} onClick={this.onClickHeader}>
        { number !== undefined && <Icon number={number} done={done} checkmark={checkmark} />}
        {this.renderTitle()}
        {this.renderHeaderTip()}
        <i className="b-step__header__arrow" />
      </div>
    );
  }
}

Header.propTypes = {
  headerTip: PropTypes.string,
  title: PropTypes.any.isRequired,
  onClickHeader: PropTypes.func,
  number: PropTypes.number,
  done: PropTypes.bool,
  checkmark: PropTypes.bool,
  foldingLocator: PropTypes.string,
  dangerousTitle: PropTypes.bool,
};

Header.defaultProps = {
  headerTip: null,
  number: null,
  done: false,
  checkmark: false,
  foldingLocator: null,
  dangerousTitle: false,
  onClickHeader: () => null,
};

module.exports = Header;
