const React = require('react');
const PropTypes = require('prop-types');
const Layout = require('../admin/layout');
const Pagination = require('./pagination');
const { formatDate } = require('../../lib/datetime_formatters');

const OfferRow = ({ offer }) => {
  const {
    id,
    inquiry_id,
    artist_name,
    artist_service,
    when,
    where,
    gross_amount_basa,
    type,
    status_name,
    view_path,
    is_full_payment,
    versions
  } = offer;

  const [isHistoryOpen, setIsHistoryOpen] = React.useState(false);

  const toggleIsHistoryOpen = (e) => {
    e.preventDefault();
    setIsHistoryOpen((currentState) => !currentState);
  };

  return (
    <>
      <tr key={id} id={`offer${id}`}>
        <td>{id}</td>
        <td>{inquiry_id}</td>
        <td>{artist_name}</td>
        <td>{artist_service}</td>
        <td>{when}</td>
        <td>{where}</td>
        <td>{gross_amount_basa}</td>
        <td>{type}</td>
        <td>{status_name}</td>
        <td>{is_full_payment ? 'Yes' : 'No'}</td>
        <td className="b-admin__actions">
          <a href={view_path}>See more</a>
        </td>
        <td>
          <a
            href="#"
            className="b-admin__actions"
            onClick={toggleIsHistoryOpen}
          >
            Show history
          </a>
        </td>
      </tr>
      {isHistoryOpen && (
        <tr>
          <td colSpan={12}>
            <table className="b-admin__change-table table table-striped">
              <thead>
              <tr>
                <th>Change History</th>
              </tr>
              </thead>
              {versions.length === 0 && (
                <tbody>
                <tr>
                  <td>No changes registered for this order</td>
                </tr>
                </tbody>
              )}
              {versions.map((version) => {
                return (
                  <tbody className="b-admin__changelist" key={version.id}>
                  <tr>
                    <td colSpan={3}>
                      Changes on {formatDate(version.created_at, 'do LLL yyyy HH:mm')}
                    </td>
                  </tr>
                  {version.object_changes
                    ? (Object.entries(version.object_changes)
                      .filter(([key]) => !['public_id', 'updated_at', 'booking_number', 'payment_id'].includes(key))
                      .map(([key, change]) => {
                        return (
                          <tr key={`${version.id}-${key}`}>
                            <td>{key}</td>
                            <td>From: <b>{change[0]}</b></td>
                            <td>To: <b>{change[1]}</b></td>
                          </tr>
                        );
                      })) : (
                      <tr>
                        <td>No changes saved</td>
                      </tr>
                    )}
                  </tbody>
                );
              })}
            </table>
          </td>
        </tr>
      )}
    </>
  );
};

OfferRow.propTypes = {
  offer: PropTypes.object.isRequired
};

const OffersIndex = ({
  offers,
  offersPath,
  exportOffersPath,
  newOfferPath,
  currentPage,
  totalPages,
  totalCount
}) => {
  const offersRows = offers.map((offer) => <OfferRow offer={offer} key={offer.id} />);
  const showExportButton = new URLSearchParams(window.location.search).get('export') === 'true'
  return (
    <Layout colClasses={['col-sm-12']}>
      <h1>Offers dashboard</h1>
      {showExportButton && <a href={exportOffersPath} className="b-button b-button--narrow">Export offers</a>}
      <div className="b-admin__submenu">
        <a href={newOfferPath} className="b-button b-button--narrow">Create offer</a>
        <h5>{totalCount} offers</h5>
      </div>
      <table className="table table-striped">
        <thead>
        <tr>
          <th>ID</th>
          <th>Inquiry ID</th>
          <th>Who</th>
          <th>What</th>
          <th>When</th>
          <th>Where</th>
          <th>Price (EUR)</th>
          <th>Type</th>
          <th>Status</th>
          <th>Full pymt?</th>
          <th>Actions</th>
          <th>History</th>
        </tr>
        </thead>
        <tbody>
        {offersRows}
        </tbody>
      </table>
      <Pagination
        rootPath={offersPath}
        currentPage={currentPage}
        pagesCount={totalPages}
      />
    </Layout>
  );
};

OffersIndex.propTypes = {
  offers: PropTypes.array.isRequired,
  offersPath: PropTypes.string.isRequired,
  exportOffersPath: PropTypes.string.isRequired,
  newOfferPath: PropTypes.string.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired
};

module.exports = OffersIndex;
