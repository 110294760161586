const React = require('react');
const Menu = require('./menu');
const PropTypes = require('prop-types');

const Layout = ({ children, colClasses }) => {
  const colClass = colClasses ? colClasses.join(' ') : 'col-sm-10 col-sm-offset-1'
  return (
    <div className="container b-admin">
      <div className="row">
        <div className={colClass}><Menu /></div>
      </div>
      <div className="row">
        <div className={colClass}>
          {children}
        </div>
      </div>
    </div>
  );
}

Layout.propTypes = {
  colClasses: PropTypes.array,
  children: PropTypes.element.isRequired,
};

Layout.defaultProps = {
  colClasses: undefined,
};

module.exports = Layout;
