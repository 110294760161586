const React = require('react');

const InputField = require('./InputField');
const ExtraCostsFields = require('./ExtraCostsInputFields');
const withArtistDashboardContext = require('../with-artist-dashboard-context');
const ContextPropTypes = require('../context-prop-types');
const { sanitizeNumericalInput } = require('../../../../../lib/sanitizers');
const { formatCurrency } = require('../../../../../lib/currency');

class ArtistPricing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showHints: false
    };
  }

  onAmountChange = (event) => {
    const service_amount = sanitizeNumericalInput(event.target.value);

    const empty_value = service_amount === '';
    const { context } = this.props;

    this.setState({ showHints: true }, () => {
      context.updateDraftOffer({ service_amount }, empty_value);
      context.clearErrors([
        'service_amount',
        'net_amount_artist_cents',
        'net_amount_basa_cents'
      ]);
    });
  };

  onVatChange = (event) => {
    const vat_percent_artist = sanitizeNumericalInput(event.target.value);
    const empty_value = vat_percent_artist === '';
    const { context } = this.props;

    context.updateDraftOffer({ vat_percent_artist }, empty_value);
    context.clearErrors(['vat_percent_artist']);
    this.setState({ showHints: true });
  };

  toggleHints = () => {
    const { showHints } = this.state;

    this.setState({
      showHints: !showHints,
    });
  };

  render() {
    const { context } = this.props;

    const {
      draftOffer,
      errors,
      computedPricing,
      isOfferSent,
      isEditingSentOffer
    } = context;

    const isInputDisabled = isOfferSent && !isEditingSentOffer;

    const grossAmountBasa = formatCurrency(
      I18n.locale,
      draftOffer.currency,
      computedPricing.gross_amount_basa
    );
    const grossAmountArtistWithExtraCosts = formatCurrency(
      I18n.locale,
      draftOffer.currency,
      computedPricing.gross_amount_artist_with_extra_costs
    );
    const totalGrossBookingValueWithExtraCosts = formatCurrency(
      I18n.locale,
      draftOffer.currency,
      computedPricing.total_gross_booking_value_with_extra_costs
    );

    return (
      <div className="row b-artist-pricing">
        <div className="col-xs-12">
          <div className="row b-artist-pricing__row">
            <div className="col-xs-8">
              <label
                className="b-artist-pricing__label"
                htmlFor="service_amount"
              >
                {I18n.t('negotiation_dashboard.artist.net_value')}
              </label>
            </div>
            <div className="col-xs-4">
              <InputField
                id="service_amount"
                placeholder="0.00"
                value={draftOffer.service_amount || 0}
                onChange={this.onAmountChange}
                disabled={isInputDisabled}
                error={errors.net_amount_artist_cents}
              />
            </div>
          </div>
          <div className="row b-artist-pricing__row">
            <div className="col-xs-8">
              <label
                className="b-artist-pricing__label"
                htmlFor="vat_amount_artist"
              >
                {I18n.t('negotiation_dashboard.artist.vat_rate')}
              </label>
            </div>
            <div className="col-xs-4">
              <InputField
                id="vat_percent_artist"
                placeholder="0"
                value={String(draftOffer.vat_percent_artist)}
                onChange={this.onVatChange}
                disabled={isInputDisabled}
                error={errors.vat_percent_artist}
              />
            </div>
          </div>
          <div className="row b-artist-pricing__row">
            <div className="col-xs-8">
              <div className="b-artist-pricing__label">
                {I18n.t('negotiation_dashboard.artist.basa_community_fee')}
                <br />
                <small>
                  {I18n.t(
                    'negotiation_dashboard.artist.basa_community_fee_vat_note'
                  )}
                </small>
              </div>
            </div>
            <div className="col-xs-4">
              <div className="b-artist-pricing__computed-value">
                - {grossAmountBasa}
              </div>
            </div>
          </div>
          <div className="row b-artist-pricing__line-break" />
          <div className="row b-artist-pricing__row b-artist-pricing__row--strong">
            <div className="col-xs-8">
              <div className="b-artist-pricing__label">
                {I18n.t(
                  'negotiation_dashboard.artist.total_amount_you_receive'
                )}
              </div>
            </div>
            <div className="col-xs-4">
              <div className="b-artist-pricing__computed-value">
                {grossAmountArtistWithExtraCosts}
              </div>
            </div>
          </div>
          <div className="row b-artist-pricing__row b-artist-pricing__row--strong">
            <div className="col-xs-8">
              <div className="b-artist-pricing__label">
                {I18n.t('negotiation_dashboard.artist.total_gross_client')}
              </div>
            </div>
            <div className="col-xs-4">
              <div className="b-artist-pricing__computed-value">
                {totalGrossBookingValueWithExtraCosts}
              </div>
            </div>
          </div>

          <ExtraCostsFields
            onChange={() => this.setState({ showHints: true })}
          />
        </div>
      </div>
    );
  }
}

ArtistPricing.propTypes = {
  context: ContextPropTypes.isRequired
};

module.exports = withArtistDashboardContext(ArtistPricing);
